import glamorous from 'glamorous';
import { BREAKPOINTS } from '@mvpf/platform-shared/constants/theme';

const Container = glamorous.div({
  maxWidth: 1020,
  marginLeft: 'auto',
  marginRight: 'auto',
  [BREAKPOINTS.medium]: {
    paddingLeft: 20,
    paddingRight: 20
  }
});

export default Container;
