import React, { Suspense, lazy } from 'react';
import { shape, bool, object } from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Network from 'react-network';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '@mvpf/platform-shared/components/Loading';
import Analytics from '@mvpf/platform-shared/components/Analytics';
// import Intercom from './components/Intercom';
import MobileDisclaimer from './components/MobileDisclaimer';
import Logout from './screens/Logout';
import NotFound from './screens/NotFound';

const Login = lazy(() => import('./screens/Login'));
const Register = lazy(() => import('./screens/Register/index'));
const RegisterCompleted = lazy(() =>
  import('./screens/Register/RegisterCompleted')
);
const ConfirmAccount = lazy(() => import('./screens/ConfirmAccount'));
const ForgotPassword = lazy(() => import('./screens/ForgotPassword'));
const ResetPassword = lazy(() => import('./screens/ResetPassword'));
const SetPassword = lazy(() => import('./screens/SetPassword'));
const Main = lazy(() => import('./screens/Main'));
const Review = lazy(() => import('./screens/Review'));
const PublicProfile = lazy(() => import('./screens/PublicProfile'));

const App = ({ data: { loading, user } }) => {
  let networkToastId;

  if (loading) {
    return <Loading fullPage />;
  }

  return (
    <div>
      <Helmet defaultTitle="MVP Factory" titleTemplate="%s - MVP Factory" />

      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/main" />} />

          <Route
            path="/login"
            render={props => <Login {...props} currentUser={user} />}
          />

          <Route
            path="/register"
            render={props => <Register {...props} currentUser={user} />}
          />

          <Route
            path="/register-completed"
            render={props => <RegisterCompleted {...props} />}
          />

          <Route
            path="/ref"
            render={({ location }) => (
              <Redirect to={{ ...location, pathname: '/register' }} />
            )}
          />

          <Route path="/logout" component={Logout} />

          <Route path="/confirm-account/:id" component={ConfirmAccount} />

          <Route
            path="/forgot-password"
            render={props => <ForgotPassword {...props} currentUser={user} />}
          />

          <Route path="/reset-password/:token" component={ResetPassword} />

          <Route path="/set-password/:token" component={SetPassword} />

          <Route
            path="/main"
            render={props => <Main {...props} currentUser={user} />}
          />

          <Route path="/review/:token" component={Review} />

          <Route path="/resume/:slug" component={PublicProfile} />

          <Route component={NotFound} />
        </Switch>
      </Suspense>

      <ToastContainer />

      <Network
        onChange={({ online }) => {
          if (online) {
            toast.dismiss(networkToastId);
          } else {
            networkToastId = toast.warn('No Internet Connection !', {
              autoClose: false
            });
          }
        }}
      />

      {process.env.REACT_APP_ENABLE_ANALYTICS === 'true' && <Analytics />}

      {/* <Intercom currentUser={user} /> */}

      {/* hide mobile disclaimer on the register, login, review, public profile and project invite page */}
      <Switch>
        <Route path="/review" />
        <Route path="/resume" />
        <Route path="/main/project-invite" />
        <Route path="/register" />
        <Route path="/login" />
        <Route path="*" component={MobileDisclaimer} />
      </Switch>
    </div>
  );
};

App.propTypes = {
  data: shape({
    loading: bool,
    error: object,
    user: object
  }).isRequired
};

export const currentUserQuery = gql`
  query currentUser {
    user {
      id
      status
      availability
      isSetupComplete
      email
      fullName
      primaryPosition
      skills {
        name
      }
      isProfileCompleted
      lastEngaged
      isWorking
      willWork
      application {
        isCompleted
      }
      shouldSubmitFeedback
    }
  }
`;

export default graphql(currentUserQuery)(App);
