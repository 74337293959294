import React, { Component } from 'react';
import { Div, Img } from 'glamorous';
import MediumScreen from './MediumScreen';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { COLORS } from '@mvpf/platform-shared/constants/theme';
import sadIcon from '../assets/smiley-sad.svg';

const LOCAL_STORAGE_KEY = 'dismissedMobileDisclaimer';

class MobileDisclaimer extends Component {
  onClose = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, true);
    this.forceUpdate();
  };

  render() {
    const hasDismissed = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (hasDismissed) {
      return null;
    }

    return (
      <MediumScreen>
        {isMediumScreen =>
          !isMediumScreen && (
            <Div
              display="flex"
              position="fixed"
              bottom={0}
              left={0}
              right={0}
              paddingTop={20}
              paddingLeft={20}
              paddingRight={30}
              // intercom and usersnap have the maximum z-index so there is no way
              // to overlay something over them, so we must place it above
              paddingBottom={80}
              backgroundColor="#fff"
              borderTop="1px solid #D1D5DF"
              borderBottom="1px solid #D1D5DF"
              zIndex={999}
            >
              <Img
                src={sadIcon}
                display="block"
                width={24}
                height={24}
                marginRight={10}
              />
              <Div color={COLORS.rollingStone}>
                Hi there! Looks like you're using a mobile device. Sadly our
                Elite App isn't quite optimised for mobile yet.
                <Div marginTop={10}>
                  <strong>
                    If you have any trouble, try opening up the app on your
                    nearest laptop or computer.
                  </strong>
                </Div>
              </Div>

              <Div position="absolute" top={0} right={0}>
                <IconButton onClick={this.onClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Div>
            </Div>
          )
        }
      </MediumScreen>
    );
  }
}

export default MobileDisclaimer;
