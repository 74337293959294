import { Component } from 'react';
import { withApollo } from 'react-apollo';
import {
  removeToken,
  removeRefreshToken
} from '@mvpf/platform-shared/utils/auth';

class Logout extends Component {
  componentDidMount() {
    removeToken();
    removeRefreshToken();
    // TODO: https://github.com/apollographql/apollo-client/issues/2774
    this.props.client.resetStore().then(() => {
      this.props.history.replace('/login');
    });
  }

  render() {
    return null;
  }
}

export default withApollo(Logout);
